.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    height: 70px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.logo {
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #333;
    text-decoration: none;
}